import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { profile } from "../../constant";

function SideBar(props) {
  const { uploadLoading, fileupload, handleImageUpload } = props;
  let currentUrl = window.location.href.split("/");
  console.log("currentUrl", currentUrl);
  return (
    <>
      <div className="col-lg-3">
        <div className="sidebar-head">
          <p>My Accounts</p>
        </div>
        <div className="profile-tabs-wrapper">
          <div className="img-and-username-wrapper">
            <div className="profile-img">
              <figure>
                {uploadLoading ? (
                  <img src={fileupload} className="img-fluid" alt="" />
                ) : (
                //   <img
                //     src={`${
                //       fileupload ? fileupload : `${userData?.profile_picture}`
                //     }`}
                //     className="img-fluid"
                //     alt=""
                //   />
                <img
                    src={`${
                      fileupload ? fileupload : profile
                    }`}
                    className="img-fluid"
                    alt=""
                  />
                )}

                {/* <a href="#" className="chnage">
                  Change image
                </a>

                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  multiple={false}
                  className="profile-upload"
                /> */}
              </figure>
            </div>
            <div className="username mt-4">
              <h3 className="username">John Doe</h3>
              <h3 className="username">johndoae@mail.com</h3>
            </div>
          
          </div>
          <div className="profile-links-wrapper">
            <div
              className="nav flex-column nav-pills"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <Link to="/profile" 
                className={`${currentUrl?.[3] === "profile" ? "nav-link active" : "nav-link"}`}
              >
                My Profile
              </Link>
              <Link to="/my-orders" 
                 className={`${currentUrl?.[3] === "my-orders" ? "nav-link active" : "nav-link"}`}
              >
                My Orders
              </Link>
              <Link to="/wishlist" 
                 className={`${currentUrl?.[3] === "wishlist" ? "nav-link active" : "nav-link"}`}
              >
                My Wishlist
              </Link>
              {/* <Link to="/my-loyalty-points" className="nav-link">
                My Loyalty Points
              </Link>
              <Link to="/my-wallet" className="nav-link">
                My Wallet
              </Link> */}
              <Link to="/" 
                  className={`${currentUrl?.[3] === "address" ? "nav-link active" : "nav-link"}`}
              >
               Logout
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SideBar;