import React, { useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/About.css";
import { about2, g1, g2, g3, g4, r1, r2, r3, star } from "../../constant";
import BestDealBanner from "../../components/Banner/BestDealBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

function About() {
  const starData = [
    {
      id:1,
    },
    {
      id:2,
    },
    {
      id:3,
    },
    {
      id:4,
    },
  ]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <section className="page-title">
        <Header />
        <div className="container">
          <div className="url-container">
            <h2 className="title">About us</h2>
          </div>
        </div>
      </section>

      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div>
                <h2 className="mission">Our Mission</h2>
                <p className="main_para">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum tempus euismod viverra. Donec luctus dolor
                  tellus,eget congue magna aliquet id. Etiam vulputate libero
                  eget quam blandit fermentum.Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit. Vestibulum tempus euismod
                  viverra. Donec luctus dolor tellus,eget congue magna aliquet
                  id.
                </p>
                <p className="main_para">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum tempus euismod viverra. Donec luctus dolor tellus,
                  eget congue magna aliquet id. Etiam vulputate libero eget quam
                  blandit fermentum.Etiam vulputate libero eget quam blandit
                  fermentum.
                </p>

                  <p className="main_para">
                    <FontAwesomeIcon icon={faCircleCheck} className="circle-icon"/>
                   Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                  <p className="main_para"> <FontAwesomeIcon icon={faCircleCheck} className="circle-icon"/>Lorem ipsum dolor sit amet, consectetur.</p>
                  <p className="main_para"> <FontAwesomeIcon icon={faCircleCheck} className="circle-icon"/>Lorem ipsum dolor sit amet,</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <div className="mission-right"
                    data-aos="fade-right"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine"
                  >
                    {" "}
                    <img src={r1} alt="" className="image-fluid" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mission-right"
                    data-aos="fade-left"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine"
                  >
                    {" "}
                    <img src={r2} alt="" className="image-fluid" />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mission-right"
                      data-aos="fade-up"
                      data-aos-anchor-placement="center-bottom"
                      data-aos-offset="300"
                      data-aos-easing="ease-in-sine"
                  >
                    {" "}
                    <img src={r3} alt="" className="image-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-6">
              <div className="gallery"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <img src={about2} alt="" className="image-fluid" />
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <div className="row star-points">
                  {
                    starData?.map((item,index)=>{
                      return(
                       <>
                           <div className="col-lg-1" key={index}>
                        <img src={star} alt="" className="image-fluid"  />
                      </div>
                      <div className="col-lg-5">
                        <p className="title">Lorem Ipsum</p>
                        <p className="desc">Lorem ipsum dolor sit amet, consectetur adipiscing  Lorem ipsum dolor sit amet, consectetur adipiscing </p>
                      </div>
                       </>
                      )
                    })
                  }
                  <div className="offset-1 col-lg-11">
                    <p className="sub-para">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vestibulum tempus euismod viverra. Donec luct us dolor
                    tellus,eget con gue magna aliquet id. Etiam vulputate libero
                    eget quam
                  </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <BestDealBanner />

      <section className="mission2">
        <div className="container">
            <h2 className="title">Our Mission</h2>
            <div className="row">
              <div className="col-lg-8 ">
                <div className="row">
                  <div className="col-lg-12 gallery">
                    <img src={g1} alt="" className="image-fluid" />
                  </div>
                  <div className="col-lg-6 gallery">
                    <img src={g3} alt="" className="image-fluid" />
                  </div>
                  <div className="col-lg-6 gallery">
                    <img src={g4} alt="" className="image-fluid" />
                  </div>
                </div>
                
              </div>
        
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-lg-12 gallery">
                    <img src={g2} alt="" className="image-fluid" /> 
                  </div>
                  <div className="col-lg-12 gallery">
                    <img src={about2} alt="" className="image-fluid" />
                  </div>
                </div>
              </div>
            </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default About;
