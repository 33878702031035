import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { cart1 } from "../../constant";

function ViewOrderModal(props) {
  const { setIsOpenModal, isOpenModal } = props;
  const navigate = useNavigate()
  // create event
  const Navigate = useNavigate();
  
  const data = [
    {
      id:1,
    },
    {
      id:2,
    },
    {
      id:3,
    },
  ]

  return (
    <>
      <Modal
        isOpen={isOpenModal}
        toggle={() => {
          setIsOpenModal(false);
        }}
        className="custom-modal modal-width orderView-Modal"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="modal-close"
          onClick={() => setIsOpenModal(false)}
        />
        <div>
          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="reservation-form">
                <div className="reservation-form-content">
                 <div className="head-modal">
                    <h2>Order Details</h2>
                    <p>Order #1084598423154 was placed on 30 April 2022</p>
                 </div>
                  {
                    data?.map((item,index)=>{
                      return(
                        <div className="view-product-card" key={index}>
                        <div>
                            <img src={cart1} alt="" className="image-fluid" />
                        </div>
                        <div className="content">
                            <p>Lorem ipsum dolor sit</p>
                            <p>$ 49.00 <span>Category : (Fashion)</span></p>
                            <p>Quantity : (02)</p>
                        </div>
                     </div>
                      )
                    })
                  }
                <div className="view-order-bottom">
                  <p className="smallPara">Sub Total :</p>
                  <p className="smallPara">$ 147.00</p>
                </div>
                <div className="view-order-bottom">
                  <p className="smallPara">Disscount Code Applied :</p>
                  <p className="smallPara">-$  14.00</p>
                </div>
                <div className="view-order-bottom">
                  <p className="smallPara">Shipping Cost :</p>
                  <p className="smallPara">$ 147.00</p>
                </div>
                <div className="view-order-bottom">
                  <p className="smallPara">Shipping Address:</p>
                  <p className="smallPara">$ 147.00</p>
                </div>
                <div className="view-order-bottom">
                  <p className="smallPara">Total Amount :</p>
                  <p className="smallPara">$ 147.00</p>
                </div>

                <div>
                  <button className="btn ContinueBtn"
                    onClick={()=>navigate('/')}
                  >Continue Shopping</button>
                </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ViewOrderModal;
