import React, { useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/Login.css";
import { Link, useNavigate } from "react-router-dom";

function Login() {
  const Navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <section className="page-title">
        <Header />
        <div className="container">
          <div className="url-container">
            <h2 className="title">Sign In</h2>
          </div>
        </div>
      </section>
      <section className="login">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="login-inner">
                <p className="title">Sign In</p>
                <p className="main-para">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy aliquyam erat, sed diam voluptua.
                </p>
                <div className="field-set">
                <div className="input-cont">
                  <p className="label">Email</p>
                  <input type="text" placeholder="John Doe@gmail.com" />
                </div>
                <div className="input-cont">
                  <p className="label">Password</p>
                  <input type="text" placeholder="********" />
                </div>
                <div className="remember-main">
                  <div className="remember-cont">
                    <input type="checkbox" id="remember" name="remember" />
                    <label>Remember Me</label>
                  </div>
                  <div>
                    <Link to="/forgot-password">Forgot Password</Link>
                  </div>
                </div>
                <div>
                  <button className="btn signin-btn">Signin</button>
                </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="right-sec">
                <h3>DON'T HAVE AN ACCOUNT?</h3>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam abore et dolore ma
                </p>
                <div>
                  <button className="btn register-btn"
                    onClick={()=>Navigate('/signup')}
                  >
                    Register Your Account
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Login;
