import React, { useEffect } from 'react'
import Footer from '../../components/Footer'
import "../../assets/css/ProductDeatils.css";
import Header from '../../components/Header';
import { productDetail, prof2 } from '../../constant';

function ProductDetails() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
    <>
         <section className="page-title">
        <Header />
        <div className="container">
          <div className="url-container">
            <h2 className="title">Product Details</h2>
          </div>
        </div>
      </section>

      {/* <!-- products-detail starts here --> */}
<section className="product-details">
    <div className="container">
        <div className="row">
            <div className="col-lg-6">
                <div className="product-detail-img-wrapper">
                    <figure>
                        <img src={productDetail} className="img-fluid" alt="" />
                    </figure>
                </div>
           
            </div>
            <div className="col-lg-6 ">
                <div className="pl-5">
                    <div className="product-name-wrapper">
                        <h4>Lorem Ipsum is simply dummy text</h4>
                    </div>
                    <div className="review-wrapper">
                        <ul>
                            <li>
                                <i className="fa fa-star"></i>
                            </li>
                            <li>
                                <i className="fa fa-star"></i>
                            </li>
                            <li>
                                <i className="fa fa-star"></i>
                            </li>
                            <li>
                                <i className="fa fa-star"></i>
                            </li>
                            <li>
                                <i className="fa fa-star"></i>
                            </li>
                            <li>
                                <span className="total-reviews">
                                    ( 119 reviews )
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className="availability-wrapper">
                        <span className="Availability">Availability :</span> &nbsp; &nbsp; &nbsp; <span className="InStock">In
                            Stock (13)</span>
                    </div>
                    <div className="product-des-wrapper">
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry.
                        </p>
                    </div>
                    <div className="product-spec-list-wrapper">
                        <ul>
                            <li>Lorem ipsum dolor sit</li>
                            <li>Lorem ipsum dolor sit</li>
                            <li>Lorem ipsum dolor sit</li>
                        </ul>
                    </div>
                    <div className="counter-and-price-wrapper">
                        <div className="price-wrapper">
                            <h2><span className="sales-price">$ 49.00</span> <strike className="regular-price">$ 59.00</strike>
                            </h2>
                        </div>
                        <div className="number">
                            <span className="plus">+</span>
                            <input type="text" value="01" />
                            <span className="minus">-</span>
                        </div>
                    </div>
                    <div className="button-wrapper">
                        <button onclick="location.href='cart.php'" className="cart-btn form-control"><i
                                className="fa fa-shopping-cart"></i> Add To Cart</button>
                        <button className="wishlist-wrapper form-control">Add To wishlist</button>
                    </div>
                  
                </div>
            </div>
        </div>
        <div>
            <img src="img/product-detialline.png" className="img-fluid" alt="" />
        </div>
        <div className="row">
            <div className="col-lg-12">
                <div className="detail-tabs-wrapper">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab"
                                aria-controls="home" aria-selected="true">Description</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab"
                                aria-controls="profile" aria-selected="false">Reviews</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div className="des-content-wrapper">
                                <h4>Product Information</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore.Lorem ipsum dolor sit amet, consectetur adipiscing
                                    elit, sed do eiusmod tempor incididunt ut labore et dolore.Lorem ipsum dolor sit
                                    amet,Lorem ipsum dolor sit amet</p>
                                <div id="accordion">
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <h5 className="mb-0">
                                                <button className="btn-link" data-toggle="collapse"
                                                    data-target="#collapseOne" aria-expanded="true"
                                                    aria-controls="collapseOne">
                                                    Ingredients and potential allergens <i className="fa fa-angle-down"></i>
                                                </button>
                                            </h5>
                                        </div>

                                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                                            data-parent="#accordion">
                                            <div className="card-body">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                tempor incididunt ut labore et dolore.Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                                                et dolor
                                                Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <h5 className="mb-0">
                                                <button className="btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapseTwo" aria-expanded="false"
                                                    aria-controls="collapseTwo">
                                                    Recommended intake <i className="fa fa-angle-down"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo"
                                            data-parent="#accordion">
                                            <div className="card-body">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                tempor incididunt ut labore et dolore.Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                                                et dolor
                                                Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h5 className="mb-0">
                                                <button className="btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapseThree" aria-expanded="false"
                                                    aria-controls="collapseThree">
                                                    Warnings and additional information <i className="fa fa-angle-down"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseThree" className="collapse show" aria-labelledby="headingThree"
                                            data-parent="#accordion">
                                            <div className="card-body">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                tempor incididunt ut labore et dolore.Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                                                et dolor
                                                Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          
                        </div>
                        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <div className="blog-replies-wrapper">
                                <div className="row">
                                    <div className="col-sm-12 pb-5">
                                        <h4>Our Clients Reviews</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore.Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                            dolore.Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet</p>
                                    </div>
                                    <div className="col-lg-1">
                                        <div className="user-img-wrapper">
                                            <figure>
                                                <img src={prof2} className="img-fluid" alt="" />
                                            </figure>
                                        </div>
                                    </div>
                                    <div className="col-lg-11">
                                        <div className="user-content-wrapper">
                                            <h5 className="replied-user-name">John Doe</h5>
                                            <div className="date-and-starts-wrapper d-flex">
                                                <h6 className="comment-date">26 March 2022</h6>
                                                <div className="rating-wrapper">
                                                    <ul>
                                                        <li>
                                                            <i className="fa fa-star"></i>
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-star"></i>
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-star"></i>
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-star"></i>
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-star"></i>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <p className="comment-content">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                                laboris nisLorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                                do eiusmod tempor incididunt ut labore et</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-1">
                                        <div className="user-img-wrapper">
                                            <figure>
                                                <img src={prof2} className="img-fluid" alt="" />
                                            </figure>
                                        </div>
                                    </div>
                                    <div className="col-lg-11">
                                        <div className="user-content-wrapper">
                                            <h5 className="replied-user-name">John Doe</h5>
                                            <div className="date-and-starts-wrapper d-flex">
                                                <h6 className="comment-date">26 March 2022</h6>
                                                <div className="rating-wrapper">
                                                    <ul>
                                                        <li>
                                                            <i className="fa fa-star"></i>
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-star"></i>
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-star"></i>
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-star-half-o"></i>
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-star-o"></i>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <p className="comment-content">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                                laboris nisLorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                                do eiusmod tempor incididunt ut labore et</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-1">
                                        <div className="user-img-wrapper">
                                            <figure>
                                                <img src={prof2} className="img-fluid" alt="" />
                                            </figure>
                                        </div>
                                    </div>
                                    <div className="col-lg-11">
                                        <div className="user-content-wrapper">
                                            <h5 className="replied-user-name">John Doe</h5>
                                            <div className="date-and-starts-wrapper d-flex">
                                                <h6 className="comment-date">26 March 2022</h6>
                                                <div className="rating-wrapper">
                                                    <ul>
                                                        <li>
                                                            <i className="fa fa-star"></i>
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-star"></i>
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-star"></i>
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-star"></i>
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-star-half-o"></i>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <p className="comment-content">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                                laboris nisLorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                                do eiusmod tempor incididunt ut labore et</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-1">
                                        <div className="user-img-wrapper">
                                            <figure>
                                                <img src={prof2} className="img-fluid" alt="" />
                                            </figure>
                                        </div>
                                    </div>
                                    <div className="col-lg-11">
                                        <div className="user-content-wrapper">
                                            <h5 className="replied-user-name">John Doe</h5>
                                            <div className="date-and-starts-wrapper d-flex">
                                                <h6 className="comment-date">26 March 2022</h6>
                                                <div className="rating-wrapper">
                                                    <ul>
                                                        <li>
                                                            <i className="fa fa-star"></i>
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-star"></i>
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-star"></i>
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-star"></i>
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-star-half-o"></i>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <p className="comment-content">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                                laboris nisLorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                                do eiusmod tempor incididunt ut labore et</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="rev-wrapper">
                                            <h4 className="mb-4">Add Review</h4>

                                            <form action="">
                                                <div className="form-group row">
                                                    <div className="col-lg-4">
                                                        <input type="text" className="form-control" placeholder="Name"/>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <input type="email" className="form-control" placeholder="Email"/>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <input type="email" className="form-control" placeholder="Rating"/>
                                                        {/* <fieldset className="rating">
                                                            <input type="radio" id="star5" name="rating"
                                                                value="5"/><label className="full" for="star5"
                                                                title="Awesome - 5 stars"></label>
                                                            <input type="radio" id="star4half" name="rating"
                                                                value="4 and a half" />
                                                                    <label className="half" for="star4half"
                                                                title="Pretty good - 4.5 stars"></label>
                                                            <input type="radio" id="star4" name="rating"
                                                                value="4"><label className="full" for="star4"
                                                                title="Pretty good - 4 stars"></label>
                                                            <input type="radio" id="star3half" name="rating"
                                                                value="3 and a half"><label className="half" for="star3half"
                                                                title="Meh - 3.5 stars"></label>
                                                            <input type="radio" id="star3" name="rating"
                                                                value="3"><label className="full" for="star3"
                                                                title="Meh - 3 stars"></label>
                                                            <input type="radio" id="star2half" name="rating"
                                                                value="2 and a half"><label className="half" for="star2half"
                                                                title="Kinda bad - 2.5 stars"></label>
                                                            <input type="radio" id="star2" name="rating"
                                                                value="2"><label className="full" for="star2"
                                                                title="Kinda bad - 2 stars"></label>
                                                            <input type="radio" id="star1half" name="rating"
                                                                value="1 and a half"><label className="half" for="star1half"
                                                                title="Meh - 1.5 stars"></label>
                                                            <input type="radio" id="star1" name="rating"
                                                                value="1"><label className="full" for="star1"
                                                                title="Sucks big time - 1 star"></label>
                                                            <input type="radio" id="starhalf" name="rating"
                                                                value="half"><label className="half" for="starhalf"
                                                                title="Sucks big time - 0.5 stars"></label>
                                                        </fieldset> */}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <textarea name="" className="form-control" placeholder="Type here" id=""
                                                        cols="30" rows="10"></textarea>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-lg-8">

                                                        <div className="form-check">
                                                            <input className='mr-2' type="checkbox" value=""
                                                                id="defaultCheck1" />
                                                            <label className="form-check-label" for="defaultCheck1">
                                                                By using this form you agree with the storage and
                                                                handling of your data by this website.
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 text-right">
                                                        <button 
                                                            className="btn submit-btn">Submit</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
{/* <!-- products-detail End here --></div> */}
<Footer />
    </>
  )
}

export default ProductDetails
