import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/Shop.css";
import { Rating } from "react-simple-star-rating";
import { p1 } from "../../constant";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

function AllProducts() {
  const [pageCount, setPageCount] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const cardData = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
    {
      id: 6,
    },
    {
      id: 7,
    },
    {
      id: 8,
    },
  ];

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  return (
    <>
      <section className="page-title">
        <Header />
        <div className="container">
          <div className="url-container">
            <h2 className="title">Shop</h2>
          </div>
        </div>
      </section>

      <section className="shop">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="cate">
                <div className="cate-box">
                  <p className="title">Category</p>
                </div>
                <ul>
                  <li>All</li>
                  <li>CBD Oil</li>
                  <li>CBD Oil 100ml</li>
                  <li>Sage </li>
                  <li>Essential Oil</li>
                  <li>T-shirts</li>
                </ul>
              </div>
              <div className="cate mt-5">
                <div className="cate-box">
                  <p className="title">Shop by Price</p>
                </div>
                <ul>
                  <li>$100 - $200</li>
                  <li>$200 - $300</li>
                  <li>$300 - $400</li>
                  <li>$400 - $500</li>
                  <li>$500 - $600</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="row">
              <div className="col-lg-6"></div>
              <div className="col-lg-6 shop-dropCollection">
                  Collection: {""}
                <select name="cars" id="cars">
                  <option value="volvo">Featured</option>
                  <option value="saab">Featured1</option>
                  <option value="mercedes">Featured2</option>
                  <option value="audi">Featured3</option>
                </select>
                </div>
              </div>
              <div className="row">
                {cardData?.map((item, index) => {
                  return (
                    <div className="col-md-4 product-card" key={index}
                    data-aos="fade-up"
                    data-aos-anchor-placement="bottom-bottom"
                    >
                      <Link to="/product-details">
                      <div className="card h-70">
                        <div className="product-img">
                          <img src={p1} alt="" className="image-fluid" />
                        </div>
                      </div>
                      <div className="content">
                        <p className="title">CBD Oil 100ml</p>
                        <p className="price">$ 45.00 $57.00.</p>
                        <div className="rating-box">
                          <Rating size={22} readonly={true} ratingValue={60} />
                        </div>
                      </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="pagination-container mt-5">
                  <ReactPaginate
                    previousLabel="<"
                    nextLabel=">"
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-end"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default AllProducts;
