import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import { FinalLogo, footerLogo } from '../constant'
import "../assets/css/Footer.css";

function Footer() {
  return (
    <section className="footer">
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <div>
            <div>
              <img src={FinalLogo} alt="" className="image-fluid footer-logo" />
            </div>
            <p className="para">
              Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's
              standard dummy
            </p>
          </div>
        </div>
        <div className="col-md-4 footer-flex">
          <div>
            <p className="greenTitle">Explore</p>
           
            <div className='d-flex'>
              <FontAwesomeIcon icon={faPhone} className=" icon-style-f" />
              <p className='ml-2'>+923456789</p>
            </div>
            <div className="footer-flex">
              <FontAwesomeIcon icon={faEnvelope}  className="icon-style-f"/>
              <p className='ml-2'>Your mail@gmail.com</p>
            </div>
          </div>
        </div>
        <div className="col-md-4 footer-flex">
          <div>
            <p className="greenTitle">Location</p>
            <div className="footer-flex">
              <FontAwesomeIcon icon={faLocationDot} className="icon-style-f" />
              <p className='ml-2'>Lorem Ipsum is simply dummy text </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row inner-cont">
        <div className="col-md-3">
          <div className='col-div'>
            <p className="title">Get Started</p>
            <hr/>
              <div  className='link-div'>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </div>
              <div  className='link-div'>
                <Link to="/shipping-policy">Shipping Policy</Link>
              </div>
              <div  className='link-div'>
                <Link to="/terms">Terms of Use</Link>
              </div>
              <div  className='link-div'>
                <Link to="">Lorem Ipsum</Link>
              </div>
              <div  className='link-div'>
                <Link to="">Lorem Ipsum</Link>
              </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className='col-div'>
            <p className="title">How It Work</p>
            <hr/>
            <div  className='link-div'>
                <Link to="">Lorem Ipsum</Link>
              </div>
              <div  className='link-div'>
                <Link to="">Lorem Ipsum</Link>
              </div>
              <div  className='link-div'>
                <Link to="">Lorem Ipsum</Link>
              </div>
              <div  className='link-div'>
                <Link to="">Lorem Ipsum</Link>
              </div>
              <div  className='link-div'>
                <Link to="">Lorem Ipsum</Link>
              </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className='col-div'>
            <p className="title">About Us</p>
            <hr/>
            <div className='link-div'>
                <Link to="">Lorem Ipsum</Link>
              </div>
              <div  className='link-div'>
                <Link to="">Lorem Ipsum</Link>
              </div>
              <div  className='link-div'>
                <Link to="">Lorem Ipsum</Link>
              </div>
              <div  className='link-div'>
                <Link to="">Lorem Ipsum</Link>
              </div>
              <div  className='link-div'>
                <Link to="">Lorem Ipsum</Link>
              </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className='col-div'>
            <p className="title">Subscribe Our Newsletter</p>
            <hr/>
            <p className='sub-text'>
              Lorem Ipsum is simply dummy text of the printing and
              typesetting industry.{" "}
            </p>
            <div>
              <input 
                type="text"
                placeholder="Enter Your Email"
              />
            </div>
            <div>
              <button type="button" class="btn subscribe">
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="copy-right">
        <div style={{display: "flex"}}>
            <div className='footer-link'>
                <FontAwesomeIcon icon={faTwitter} />
            </div>
            <div className='footer-link'>
                <FontAwesomeIcon icon={faFacebook} />
            </div>
            <div className='footer-link'>
                <FontAwesomeIcon icon={faInstagram}  />
            </div>
        </div>
        <div>
          <p className='text'>2022  Your Website Name . All rights reserved</p>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Footer