import React from 'react'
import Footer from '../Footer'
import Header from '../Header'

function NotFound() {
  return (
    <>
        <Header/>
            <section>
                <div className='container'>
                    <p>404 NOT FOUND</p>
                </div>
            </section>
        <Footer />
    </>
  )
}

export default NotFound