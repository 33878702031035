import React from 'react'
import Slider from 'react-slick';
import { profile } from '../../constant';

function TestimonialSlider() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };

      const data = [
        {
            id:1,
        },
        {
            id:2,
        },
        {
            id:3,
        },
        {
            id:4,
        },
      ]
  return (
    <div>
        <Slider {...settings}>
         {
            data?.map((item,index)=>{
                return(
                    <div className="testimonails-wrapper" key={index}>
                        <div className="testimonails-img-wrapper text-center">
                            <figure>
                                <img src={profile} className="img-fluid" alt="" />
                            </figure>
                        </div>
                        <div className="testimonails-content-wrapper text-center">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur</p>
                            <h6>James Alberuni</h6>
                            <div className="rating-wrapper">
                                <ul>
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fa fa-star"></i></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            })
         }
        </Slider>
      </div>
  )
}

export default TestimonialSlider