import React, { useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/Pages.css"

function Shipping() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <section className="page-title">
        <Header />
        <div className="container">
          <div className="url-container">
            <h2 className="title">Shipping Policy</h2>
          </div>
        </div>
      </section>

      <section className="pages">
        <div className="container">
        <p className="title">Shipping Policy</p>
        <h2>Lorem Ipsum is simply</h2>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been Lorem Ipsum is simply dummy text of the
          printing and typesetting industry. sum has been Lorem Ipsum is simply
          dummy text of the printing Lorem Ipsum is simply dummy text of the
          printing and typesetting industry. Lorem Ipsum has been Lorem Ipsum is
          simply dummy text of the printing and typesetting industry. sum has
          been Lorem Ipsum is simply dummy text of the printing Lorem Ipsum is
          simply dummy text of the printing and typesetting industry. Lorem
          Ipsum has been Lorem Ipsum is simply dummy text of the printing and
          typesetting industry. sum has been Lorem Ipsum is simply dummy text of
          the printing Lorem Ipsum is simply dummy text of the printing and
          typesetting industry. Lorem Ipsum has been Lorem Ipsum is simply dummy
          text of the printing and typesetting industry. sum has been Lorem
          Ipsum is simply dummy text of the printing{" "}
        </p>

        <h2>Heading Here</h2>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been Lorem Ipsum is simply dummy text of the
          printing and typesetting industry. sum has been Lorem Ipsum is simply
          dummy text of the printing Lorem Ipsum is simply dummy text of the
          printing and typesetting industry. Lorem Ipsum has been Lorem Ipsum is
          simply dummy text of the printing and typesetting industry. sum has
          been Lorem Ipsum is simply dummy text of the printing Lorem Ipsum is
          simply dummy text of the printing and typesetting industry. Lorem
          Ipsum has been Lorem Ipsum is simply dummy text of the printing and
          typesetting industry. sum has been Lorem Ipsum is simply dummy text of
          the printing Lorem Ipsum is simply dummy text of the printing and
          typesetting industry. Lorem Ipsum has been Lorem Ipsum is simply dummy
          text of the printing and typesetting industry. sum has been Lorem
          Ipsum is simply dummy text of the printing Lorem Ipsum is simply dummy
          text of the printing and typesetting industry. Lorem Ipsum has been
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. sum has been Lorem Ipsum is simply dummy text of the
          printing Lorem Ipsum is simply dummy text of the printing and
          typesetting industry. Lorem Ipsum has been Lorem Ipsum is simply dummy
          text of the printing and typesetting industry. sum has been Lorem
          Ipsum is simply dummy text of the printing Lorem Ipsum is simply dummy
          text of the printing and typesetting industry. Lorem Ipsum has been
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. sum has been Lorem Ipsum is simply dummy text of the
          printing Lorem Ipsum is simply dummy text of the printing and
          typesetting industry. Lorem Ipsum has been Lorem Ipsum is simply dummy
          text of the printing and typesetting industry. sum has been Lorem
          Ipsum is simply dummy text of the printing{" "}
        </p>

        <h2>Your Heading Goes Here</h2>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been Lorem Ipsum is simply dummy text of the
          printing and typesetting industry. sum has been Lorem Ipsum is simply
          dummy text of the printing{" "}
        </p>
        <h2>Tere are many variations of passages of Lorem Ipsum</h2>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Shipping;
