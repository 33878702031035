import React, { useEffect, useState } from "react";
import "../../assets/css/Profile.css";
import SideBar from "../../components/SideBar/SideBar";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ViewOrderModal from "../../components/Modal/ViewOrderModal";

function MyOrders() {
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [spinLoad, setSpinLoad] = useState(false);
  //   const [myOrders, setMyOrders] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  const myOrders = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
        id: 3,
    },
    {
        id: 4,
    },
  ];

  return (
    <>
      <section className="page-title">
        <Header />
        <div className="container">
          <div className="url-container">
            <h2 className="title">My Orders</h2>
          </div>
        </div>
      </section>

      <section className="profile">
        <div className="container">
          <div className="row profile-row">
            <SideBar />
            <div className="col-lg-8">
              <div>
                {/* <!-- orders starts here --> */}
                <div className="row">
                  <div className="col-lg-12">
                    <h3 className="form-heading">My Orders</h3>
                    <div className="order-table-wrapper table-responsive">
                      <table>
                        <tbody>
                          <tr className="row1">
                            <th className="order-no">OrderNo</th>
                            <th className="order-date">Order Date</th>
                            <th className="order-status">Status</th>
                            <th className="order-status">Payment Status</th>
                            <th className="order-pay">Total</th>
                            <th className="details"></th>
                          </tr>
                          {myOrders?.map((item, index) => {
                            return (
                              <>
                                <tr className="row2" key={index}>
                                  <td className="number">#124332</td>
                                  <td className="date">04-12-22</td>
                                  <td className="date">Confirm</td>
                                  <td className="paid confirm">
                                    Paid
                                    <i
                                      className="fa fa-check-circle"
                                      aria-hidden="true"
                                    ></i>
                                  </td>
                                  <td className="total-price">$7212</td>
                               
                                  <td className="order-details">
                                    <a className="order-details-btn" 
                                      onClick={()=>setIsOpenModal(true)}
                                    >
                                      view Details
                                    </a>
                                  </td>
                                </tr>
                                <tr className="spacer"></tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      
      <ViewOrderModal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
      />
    </>
  );
}

export default MyOrders;
