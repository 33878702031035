import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFound from "../components/404/NotFound";
import About from "../pages/About/About";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import Login from "../pages/Auth/Login";
import ResetPassword from "../pages/Auth/ResetPassword";
import Signup from "../pages/Auth/Signup";
import Cart from "../pages/Cart/Cart";
import Checkout from "../pages/Cart/Checkout";
import Contact from "../pages/Contact/Contact";
import Home from "../pages/Home/Home";
import Privacy from "../pages/Policy/Privacy";
import Shipping from "../pages/Policy/Shipping";
import Terms from "../pages/Policy/Terms";
import AllProducts from "../pages/Products/AllProducts";
import ProductDetails from "../pages/Products/ProductDetails";
import MyOrders from "../pages/Profile/MyOrders";
import Profile from "../pages/Profile/Profile";
import Wishlist from "../pages/Profile/Wishlist";
import ProtectedRoutes from "./ProtectedRoutes";
// import ProtectedRoutes from "./ProtectedRoutes";

function PublicRoutes(props) {

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about-us" element={<About />} />
          <Route path="shop" element={<AllProducts />} />
          <Route path="product-details" element={<ProductDetails />} />
          <Route path="check-out" element={<Checkout />} />
          <Route path="cart" element={<Cart />} />
          <Route path="contact" element={<Contact />} />

          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="privacy-policy" element={<Privacy />} />
          <Route path="shipping-policy" element={<Shipping />} />
          <Route path="terms" element={<Terms />} />

          <Route path="/*" element={<NotFound />} />

          <Route path="/" element={<ProtectedRoutes />}>
            <Route path="profile" element={<Profile />} /> 
            <Route path="my-orders" element={<MyOrders />} />
            <Route path="wishlist" element={<Wishlist />} />

          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default PublicRoutes;
