import React, { useEffect } from 'react'
import Header from '../../components/Header'
import "../../assets/css/Contact.css";
import Footer from '../../components/Footer';

function Contact() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <>
         <section className="page-title">
        <Header />
        <div className="container">
          <div className="url-container">
            <h2 className="title">Contact us</h2>
          </div>
        </div>
      </section>

{/* <!-- Contact Sec Start Here --> */}
<section className="contact_sec">
    <div className="container">
        <div className="row">
            <div className="col-md-4 right-sec">
                <div className="title">
                    <h3>Contact Information</h3>

                    <p className='main-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                      Lorem Ipsum has been the industry's standard dummy. </p>
                </div>
                <div className="all_info">
                    <div className="info_box">
                        <div className="topbar">
                            <div className="iconDv">
                                <i className="fa fa-phone" aria-hidden="true"></i>
                            </div>
                            <div className="heading">
                                <h4>Phone</h4>
                            </div>
                        </div>
                        <div className="content">
                            <ul className="info">
                                <li><span>Toll Free :</span><a href="tel:0000-0000-0000"> 0000-0000-0000</a></li>
                                <li><span>Fax :</span><a href="tel:1232-456-7489"> 1232-456-7489</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="info_box">
                        <div className="topbar">
                            <div className="iconDv">
                                <i className="fa fa-envelope" aria-hidden="true"></i>
                            </div>
                            <div className="heading">
                                <h4>Email</h4>
                            </div>
                        </div>
                        <div className="content">
                            <ul className="info">
                                <li><a href="mailto:mail@example.com">mail@example.com</a></li>
                                <li><a href="mailto:mail@example.com">mail@example.com</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="info_box">
                        <div className="topbar">
                            <div className="iconDv">
                                <i className="fa fa-map-marker" aria-hidden="true"></i>
                            </div>
                            <div className="heading">
                                <h4>Address</h4>
                            </div>
                        </div>
                        <div className="content">
                            <ul className="info">
                                <li>Alienum phaedrum torquatos<br />
                                    nec eu, vis d</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-7">
                <form action="">
                        <p className='gt-touch'>Get In Touch</p>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <input type="text" placeholder="First Name" className="form-control"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <input type="text" placeholder="Last Name" className="form-control"/>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <input type="email" placeholder="Email" className="form-control"/>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <input type="tel" placeholder="Phone Number" className="form-control" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <textarea placeholder="Your Message" className="form-control"></textarea>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="button-group">
                                <button className="btn">Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
{/* <!-- Contact Sec End Here --> */}

      {/* <!-- Map Sec Start Here --> */}
      <section className="map_sec">
        <div className="google_map">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26359294.213879213!2d-113.71754000532059!3d36.247089826313314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2s!4v1650567615228!5m2!1sen!2s"
                width="100%" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

</section>
{/* <!-- Map Sec End Here --> */}
      <Footer />
    </>
  )
}

export default Contact