import React, { useEffect, useState } from "react";

import "../../assets/css/Profile.css";
import SideBar from "../../components/SideBar/SideBar";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { profile } from "../../constant";

function Profile() {
  const [editState, setEditState] = useState(false);
  const [passwordChange, setPasswordChange] = useState(false);
  const [loading, setLoading] = useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passLoading, setPassLoading] = useState(false);

  const [newFile, setNewFile] = useState("");
  const [fileupload, setFileupload] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phone, setPhone] = useState();
  const [editLoading, setEditLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const LogoutHandler = (e) => {
    e.preventDefault();
  };

  const changePassword = (e) => {
    e.preventDefault();
  };

  const handleImageUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setNewFile(file);
      setFileupload(reader.result);
    };
    reader.readAsDataURL(file);
    setUploadLoading(true);
  };

  const EditProfile = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <section className="page-title">
        <Header />
        <div className="container">
          <div className="url-container">
            <h2 className="title">Profile</h2>
          </div>
        </div>
      </section>
      <section className="profile">
        <div className="container">
          <div className="row profile-row">
            <SideBar
              uploadLoading={uploadLoading}
              fileupload={fileupload}
              handleImageUpload={handleImageUpload}
            />
            <div className="col-lg-9">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade active show"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <p className="main-head">{editState ? "Edit Information": "Basic Information"}</p>

                  <div className="row info-profile">
                    <div className="col-md-12">
                      <div className="text-right">
                        {editState ? (
                          <div className="user-upload">
                            <div className="left-side">
                              <div className="img-cont">
                                <img
                                  src={profile}
                                  alt=""
                                  className="image-fluid"
                                />
                              </div>
                              <div>
                                <p className="upload-text">
                                  Jpeg or Png maximum size (5mb)
                                </p>
                                <div className="up-container">
                                  <button className="btn upload-picture">
                                    Upload
                                  </button>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                    multiple={false}
                                    className="upload-hide"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="right-side">
                              <div className="text-right">
                            
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="user-upload">
                            <div className="left-side">
                              <div className="img-cont">
                                <img
                                  src={profile}
                                  alt=""
                                  className="image-fluid"
                                />
                              </div>
                              <div>
                                <p className="upload-text">Photo</p>
                                <p className="upload-text">
                                  Upload / Change Photo
                                </p>
                              </div>
                            </div>
                            <div className="right-side">
                              <div className="text-right">
                                <button
                                  className="btn btn-large logout-btn mr-2"
                                  onClick={() => setEditState(true)}
                                >
                                  EDIT PROFILE
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="Accounts">
                        <div>
                          <div className=""></div>
                        </div>
                      </div>

                      {editState ? (
                        <div className="col-lg-10">
                          <div className="account-form-wrapper">
                            <form action="">
                              <div className="form-group row">
                                <div className="col-lg-6">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="First Name"
                                    value={firstName}
                                    onChange={(e) =>
                                      setFirstName(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="col-lg-6">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="last Name"
                                    value={lastName}
                                    onChange={(e) =>
                                      setLastName(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <div className="col-lg-6">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Phone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                  />
                                </div>

                                <div className="col-lg-6">
                                  <select
                                    name="gender"
                                    id="gender"
                                    className="form-control"
                                  >
                                    <option value="volvo">Gender</option>
                                    <option value="volvo">Male</option>
                                    <option value="saab">Female</option>
                                  </select>
                                </div>

                                <div className="col-lg-12 mt-4 save-cont-prof">
                                <button
                                  className="btn btn-large logout-btn mr-2"
                                  onClick={(e) => EditProfile(e)}
                                  disabled={editLoading}
                                >
                                  {editLoading ? "Loading..." : "Save Changes"}
                                </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="row">
                            <div className="col-lg-6 user-info">
                              <div className="first-name">
                                <h4>First Name</h4>
                                <h3>John</h3>
                              </div>
                            </div>

                            <div className="col-lg-6 user-info">
                              <div className="first-name">
                                <h4>Last Name</h4>
                                <h3>Doe</h3>
                              </div>
                            </div>

                            <div className="col-lg-6 user-info">
                              <div className="first-name">
                                <h4>Gender</h4>
                                <h3>Male</h3>
                              </div>
                            </div>

                            <div className="col-lg-6 user-info">
                              <div className="first-name">
                                <h4>Email</h4>
                                <h3>johndoe@gmail.com</h3>
                              </div>
                            </div>

                            <div className="col-lg-6 user-info">
                              <div className="first-name">
                                <h4>Phone</h4>
                                <h3>+123456789</h3>
                              </div>
                            </div>

                            <div className="col-lg-6 user-info">
                              <div className="password-btn">
                                <button
                                  onClick={(e) => setPasswordChange(true)}
                                >
                                  Change Password
                                </button>
                              </div>
                            </div>
                          </div>
                          <hr className="hr-line" />
                          <div className="col-lg-12 address-main">
                            <div className="address-top">
                              <p className="home-title">Home Address</p>
                              <p>
                                <Link to="/">Change Address</Link>
                              </p>
                            </div>
                            <p className="desc">
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's
                            </p>
                            <div className="row">
                              <div className="col-lg-3">
                                <div className="location">
                                  <p className="main">City</p>
                                  <p className="sub">Lorem Ipsum</p>
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="location">
                                  <p className="main">State</p>
                                  <p className="sub">Lorem Ipsum</p>
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="location">
                                  <p className="main">Province</p>
                                  <p className="sub">Lorem Ipsum</p>
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="location">
                                  <p className="main">Zip Code</p>
                                  <p className="sub">Lorem Ipsum</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {passwordChange ? (
                    <div className="security-form-wrapper pt-5">
                      <h3 className="form-heading">Change Password</h3>
                      <form action="">
                        <div className="form-group row">
                          <div className="col-lg-6">
                            <label for="oldpassword">Old Password</label>
                            <input
                              type="password"
                              className="form-control"
                              value={currentPassword}
                              onChange={(e) =>
                                setCurrentPassword(e.target.value)
                              }
                            />
                          </div>
                          <div className="col-lg-6">
                            <label for="newpassword">New Password</label>
                            <input
                              type="password"
                              className="form-control"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg-6">
                            <label for="confirmpassword">
                              Confirm Password
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              value={confirmPassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <button
                          className="btn btn-submit"
                          onClick={(e) => changePassword(e)}
                          disabled={passLoading}
                        >
                          {passLoading ? "Loading.." : "SAVE PASSWORD "}
                          &nbsp; {/* <img src={rightArrow} alt="" /> */}
                        </button>
                      </form>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Profile;
