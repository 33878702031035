import React, { useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import SideBar from "../../components/SideBar/SideBar";
import "../../assets/css/Profile.css";
import { Link } from "react-router-dom";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cart1 } from "../../constant";

function Wishlist() {
  const data = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <section className="page-title">
        <Header />
        <div className="container">
          <div className="url-container">
            <h2 className="title">My Wishlist</h2>
          </div>
        </div>
      </section>

      <section className="profile">
        <div className="container">
          <div className="row profile-row">
            <SideBar />
            <div className="col-lg-8">
              <div>
                {/* <!-- orders starts here --> */}
                <div className="row">
                  <div className="col-lg-12">
                    <h3 className="form-heading">My Wishlist</h3>
                    <div className="order-table-wrapper ">
                      <div className="row">
                        {data?.map((item, index) => {
                          return (
                            <div className="col-lg-12">
                              <div className="wish-card" key={index}>
                                <div className="row">
                                  <div className="col-lg-1 cancel">
                                    <FontAwesomeIcon
                                      icon={faXmark}
                                      className="cancel-icon"
                                    />
                                  </div>
                                  <div className="col-lg-7">
                                    <div className="product-main">
                                      <div className="img-box">
                                        <img
                                          src={cart1}
                                          alt=""
                                          className="image-fluid"
                                        />
                                      </div>
                                      <div className="pro-detail">
                                        <p className="title">
                                          Lore Ipsum dolor sit
                                        </p>
                                        <p className="title">$49.00</p>
                                        <p className="stock">
                                          In Stock: <span>(02)</span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="btn-container">
                                      <button className="btn add-cart-btn">
                                        Add to cart
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Wishlist;
