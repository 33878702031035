import './assets/css/Style.css'
import PublicRoutes from './routes/PublicRoutes';

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import 'react-loading-skeleton/dist/skeleton.css'
import AOS from 'aos';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    setTimeout(() => {
      AOS.init();
    }, 1000);
  }, [])

  return (
    <>
       <PublicRoutes />
      <ToastContainer />
    </>
   
  );
}

export default App;
