import React, { useEffect } from 'react'
import { bannerOil } from '../../constant'
import AOS from 'aos';

function BestDealBanner() {
  useEffect(() => {
    setTimeout(() => {
      AOS.init();
    }, 1000);
  }, [])

  return (
    <section className="deal-banner-count">
    <div className="container">
      <div className="row">
        <div className="col-md-8 banner-content">
          <p className='sm-deal'>Deal</p>
          <p className="mainTitle"
            data-aos="zoom-out-right"
            data-aos-offset="500"
            data-aos-duration="500"
          >Best Deal Of the Day</p>
          <p className="para">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standar
          </p>
          <div className="counter-container">
            <div className="counter-card">
              <div>
                <p>Days</p>
                <p>04</p>
              </div>
            </div>
            <div className="counter-card">
              <div>
                <p>Hrs</p>
                <p>12</p>
              </div>
            </div>
            <div className="counter-card">
              <div>
                <p>Mins</p>
                <p>54</p>
              </div>
            </div>
            <div className="counter-card">
              <div>
                <p>Sec</p>
                <p>33</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="banner-right">
            <img src={bannerOil} alt="oil" className="image-fluid" 
              data-aos="fade-down-left" 
              data-aos-offset="500"
              data-aos-duration="500"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default BestDealBanner