import React, { useEffect } from 'react'
import Header from '../../components/Header'
import "../../assets/css/Cart.css";
import Footer from '../../components/Footer';
import { cart1 } from '../../constant';
import { useNavigate } from 'react-router-dom';

function Cart() {
    const Navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const cartData = [
        {
            id:1,
        },
        {
            id:2,
        },
        {
            id:3,
        },
        {
            id:4,
        },
        {
            id:5,
        },
    ]
  return (
    <>
        <section className="page-title">
    <Header />
    <div className="container">
      <div className="url-container">
        <h2 className="title">Cart</h2>
      </div>
    </div>
  </section>

  {/* <!-- checkout-section starts here --> */}
<section className="cart">
    <div className="container">
        <div className="row pb-5">
            <div className="col-lg-12">
                <div className="cart-table-wrapper">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Product Name</th>
                                    <th scope="col">Unit Price</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Sub Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    cartData?.map((item,index)=>{
                                        return(
                                            <tr>
                                    <td className="close-row">
                                        <button> <i className="fa fa-close"></i></button>
                                    </td>
                                    <td className="pro-img">
                                        <div className="img-wrapper">
                                            <img src={cart1} className="img-fluid" alt="" />
                                        </div>
                                    </td>
                                    <td className="pro-name">Lorem ipsum dolor
                                        sit</td>
                                    <td className="total-price">$ 49.00</td>
                                    <td>
                                        <div className="number">
                                            <span className="plus">+</span>
                                            <input type="text" value="01" />
                                            <span className="minus">-</span>
                                        </div>
                                    </td>
                                    <td className="sub-total">$ 49.00</td>
                                </tr> 
                                        )
                                    })
                                }
                               
                            </tbody>
                        </table>
                    </div>
                    <div className="button-group text-right">
                        <button  className="btn">Update Cart</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-6"></div>
            <div className="col-lg-6">
                <div className="cart-t-tal-wrapper">
                    <div className="cart-header">
                        <h5 className="text-center cart-total">Cart Total</h5>
                    </div>
                    <div className="cart-content-wrapper">
                        <ul>
                            <li>
                                <span className="property">Subtotal :</span>
                                <span className="value">$ 49.00</span>
                            </li>
                            <li>
                                <span className="property">Shipping Cost :</span>
                                <span className="value">$ 15.00</span>
                            </li>
                            <li>
                                <span className="property">Discount Code :</span>
                                <span className="value">$ - 10.00</span>
                            </li>
                        </ul>
                    </div>
                    <div className="cart-footer-wrapper">
                        <ul>
                            <li><span className="property">Total Amount :</span> <span className="value">$ 59.00</span> </li>
                        </ul>
                    </div>
                </div>
                <div className="button-group-total d-flex justify-content-between pt-4">
                    <button className="btn contine-shop">Continue Shopping</button>
                    <button  className="btn pro-shop"
                        onClick={()=>Navigate("/check-out")}
                    >Proceed To checkout</button>
                </div>
            </div>
        </div>
    </div>
</section>
{/* <!-- checkout-section ends here --> */}

  <Footer />
    </>
  )
}

export default Cart