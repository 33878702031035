import React, { useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/Login.css";
import { Link, useLocation } from "react-router-dom";

function Signup() {
  const location = useLocation()
  console.log(location?.state)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <section className="page-title">
        <Header />
        <div className="container">
          <div className="url-container">
            <h2 className="title">Sign Up</h2>
          </div>
        </div>
      </section>
      <section className="login">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="login-inner">
                <p className="title">Sign Up</p>
                <p className="main-para">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy aliquyam erat, sed diam voluptua.
                </p>
                <div className="field-set">
                  <div className="row">
                    <div className="col-lg-6 input-cont">
                      <p className="label">First Name</p>
                      <input type="text" placeholder="John" />
                    </div>

                    <div className="col-lg-6 input-cont">
                      <p className="label">Last Name</p>
                      <input type="text" placeholder=" Doe" />
                    </div>

                    <div className="col-lg-12 input-cont2">
                      <p className="label">Email</p>
                      <input type="text" placeholder="John Doe@gmail.com" />
                    </div>

                    <div className="col-lg-12 input-cont2">
                      <p className="label">Contact Number</p>
                      <input type="text" placeholder="+123456789" />
                    </div>

                    <div className="col-lg-6 input-cont2">
                      <p className="label">Password</p>
                      <input type="text" placeholder="********" />
                    </div>
                    <div className="col-lg-6 input-cont2">
                      <p className="label">Confirm Password</p>
                      <input type="text" placeholder="********" />
                    </div>
                  </div>

                  <div>
                    <button className="btn signin-btn">Signup</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
             
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Signup;
