import React, { useEffect } from "react";
import Header from "../../components/Header";
import "../../assets/css/Home.css";
import {
  bannerOil,
  footerLogo,
  logo,
  p1,
  profile,
  slidecate1,
  slidecate2,
  slidecate3,
} from "../../constant";
import { Rating } from "react-simple-star-rating";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "../../components/Footer";
import BestDealBanner from "../../components/Banner/BestDealBanner";
import TestimonialSlider from "../../components/Sliders/TestimonialSlider";
import AOS from "aos";
import TypeAnimation from "react-type-animation";

function Home() {
  useEffect(() => {
    setTimeout(() => {
      AOS.init();
    }, 1000);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const cardData = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
    {
      id: 6,
    },
    {
      id: 7,
    },
    {
      id: 8,
    },
  ];

  const HomeSubSlider = [
    {
      id: 1,
      title: "Sage Natural Products",
      para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
      link: "",
      img: slidecate1,
    },
    {
      id: 1,
      title: "Essential Oils",
      para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
      link: "",
      img: slidecate2,
    },
    {
      id: 1,
      title: "Necklace Pendent",
      para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
      link: "",
      img: slidecate3,
    },
  ];
  return (
    <>
      <section className="home-banner">
        <Header />
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="content-container">
                <p
                  className="shop-best"
                  data-aos="fade-right"
                  data-aos-offset="310"
                  data-aos-easing="ease-in-sine"
                >
                  Shop The Best
                </p>
                <h2>
                  <TypeAnimation
                    cursor={false}
                    sequence={["Get All The Good Stuff", 3000, ""]}
                    wrapper="h2"
                    className="title"
                    repeat={Infinity}
                  />
                </h2>
                {/* <h2 className="title">
                  <TypeAnimation
                    cursor={false}
                    sequence={["Stuff", 1000, ""]}
                    wrapper="h2"
                    className="title"
                    repeat={Infinity}
                  />
                </h2> */}
                <p
                  className="para"
                  data-aos="fade-right"
                  data-aos-offset="330"
                  data-aos-easing="ease-in-sine"
                >
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s when an unknown
                  printer took a galley.
                </p>
                <div
                  className="btn-container"
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-offset="330"
                  data-aos-easing="ease-in-sine"
                >
                  <button type="button" className="btn shopNowBtn">
                    Shop Now
                  </button>
                  <button type="button" className="btn learnMoreBtn">
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-cate-slider">
        <div className="container">
          <div className="row">
            {HomeSubSlider?.map((item, index) => {
              return (
                <>
                  <div
                    className="col-md-4 cate-card"
                    key={index}
                    style={{ backgroundImage: `url(${item?.img})` }}
                  >
                    <div className="content">
                      <h3 className="title">{item?.title}</h3>
                      <p className="para">{item?.para}</p>
                      <Link to="" className="link">
                        View more
                      </Link>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>

      <section className="new-arrival">
        <div className="container">
          <div>
            <h2 className="newarrival-title">New Arrivals</h2>
            <p className="sub-para">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's <br />
              standard dummy
            </p>
          </div>
          <div className="home-product-list">
            <div className="row">
              {cardData?.map((item, index) => {
                return (
                  <div
                    className="col-lg-3 product-card"
                    key={index}
                    data-aos="fade-up"
                    data-aos-anchor-placement="bottom-bottom"
                  >
                    <div className="card h-70">
                      <div className="product-img">
                        <img src={p1} alt="" className="image-fluid" />
                      </div>
                    </div>
                    <div className="content">
                      <p className="title">CBD Oil 100ml</p>
                      <p className="price">$ 45.00 $57.00.</p>
                      <div className="rating-box">
                        <Rating size={22} readonly={true} ratingValue={60} />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      <BestDealBanner />
      <section className="top-products">
        <div className="container">
          <h2 className="newarrival-title">Top Products</h2>
          <p className="sub-para">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy
          </p>
          <div className="row">
            {cardData?.map((item, index) => {
              return (
                <div
                  className="col-md-3 product-card"
                  key={index}
                  data-aos="fade-up"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  <div className="card h-70">
                    <div className="product-img">
                      <img src={p1} alt="" className="image-fluid" />
                    </div>
                  </div>
                  <div className="content">
                    <p className="title">CBD Oil 100ml</p>
                    <p className="price">$ 45.00 $57.00.</p>
                    <div className="rating-box">
                      <Rating size={22} readonly={true} ratingValue={60} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="alibaba-cover ">
        <div className="container">
          <div className="row">
            <div className="col-md-8 banner-content">
              <p className="deal">Alibaba Deals</p>
              <p
                className="mainTitle"
                data-aos="zoom-out-right"
                data-aos-offset="500"
                data-aos-duration="500"
              >
                Best OF Alibaba Deals
              </p>
              <p className="para">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standar
              </p>
              <div
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
                data-aos-offset="330"
                data-aos-easing="ease-in-sine"
              >
                <button className="btn shop_now">Shop Now</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="top-products">
        <div className="container">
          <h2 className="newarrival-title">Best Seller Product</h2>
          <p className="sub-para">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's <br />
            standard dummy
          </p>
          <div className="row">
            {cardData?.map((item, index) => {
              return (
                <div
                  className="col-md-3 product-card"
                  key={index}
                  data-aos="fade-up"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  <div className="card h-70">
                    <div className="product-img">
                      <img src={p1} alt="" className="image-fluid" />
                    </div>
                  </div>
                  <div className="content">
                    <p className="title">CBD Oil 100ml</p>
                    <p className="price">$ 45.00 $57.00.</p>
                    <div className="rating-box">
                      <Rating size={22} readonly={true} ratingValue={60} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <div className="small-banners">
        <div className="container">
          <div className="row">
            <div
              className="col-md-6 cbd-cont"
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
            >
              <div className="inner-container">
                <p className="title">CBD Origional Products</p>
                <p className="para">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's{" "}
                </p>
                <Link to="" className="link">
                  View more
                </Link>
              </div>
            </div>
            <div
              className="col-md-6 tshirt-cont"
              data-aos="fade-left"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
            >
              <div className="inner-container">
                <p className="title">T.Shirts New Arrival</p>
                <p className="para">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's{" "}
                </p>
                <Link to="" className="link">
                  View more
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- testimonials starts here --> */}
      <section className="testimonails">
        <div className="container">
          <div className="row pb-4">
            <div className="col-lg-3"></div>
            <div
              className="col-lg-6"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
            >
              <div className="content-wrapper text-center">
                <h2>Our Happy Customers</h2>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy
                </p>
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div
                className="testimonails-slider"
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
                data-aos-offset="350"
                data-aos-easing="ease-in-sine"
              >
                <TestimonialSlider />
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </section>
      {/* <!-- testimonials ends here --> */}

      {/* <section className="testimonial">
        <div className="container">
          <h2 className="title">Our Happy Customers</h2>
          <p className="sub-para">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has <br />
            been the industry's standard dummy
          </p>

          <div className="testi-card">
            <div className="profile">
              <img src={profile} className="image-fluid" alt="" />
            </div>
            <p className="para">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
              ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
              accumsan lacus vel facilisis. Lorem ipsum dolor sit amet,
              consectetur
            </p>
            <p className="clients">James Alberuni</p>
            <div className="rating-box">
              <Rating size={22} readonly={true} ratingValue={60} />
            </div>
          </div>
        </div>
      </section> */}
      <Footer />
    </>
  );
}

export default Home;
