import React, { useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/Login.css";
import { Link, useNavigate } from "react-router-dom";

function ForgotPassword() {
    const Navigate = useNavigate()
    const ForgotHandler = (e) =>{
        e.preventDefault()
        Navigate("/reset-password")
    }

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
  return (
    <>
    <section className="page-title">
      <Header />
      <div className="container">
        <div className="url-container">
          <h2 className="title">Forgot Password</h2>
        </div>
      </div>
    </section>
    <section className="login">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="login-inner">
              <p className="title">Forgot Password</p>
              <p className="main-para">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy aliquyam erat, sed diam voluptua.
              </p>
              <div className="field-set">
                <div className="row">
                  <div className="col-lg-12 input-cont">
                    <p className="label">Enter Email Address</p>
                    <input type="text" placeholder="John Doe@gmail.com" />
                  </div>
                </div>

                <div>
                  <button className="btn signin-btn"
                    onClick={(e)=>ForgotHandler(e)}
                  >Send</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
           
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </>
  )
}

export default ForgotPassword