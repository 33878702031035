import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faMagnifyingGlass,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import "../assets/css/Header.css";
import { FinalLogo, logo } from "../constant";

function Header() {
  const Navigate = useNavigate();
  const Token = true

  let currentUrl = window.location.href.split("/");
  console.log(currentUrl);
  const [mobView, setMobView] = useState(false);
  const [loading, setLoading] = useState(false);

  const NavBarHandler = () => {
    setMobView(!mobView);
  };

  return (
    <>
      <header>
        <div className="main-header">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-2 col-sm-6 col-6">
                <div className="logoDv">
                  <figure>
                    <Link to="/">
                      <img src={FinalLogo} className="img-fluid" alt="Logo" />
                    </Link>
                  </figure>
                </div>
              </div>
              <div className="col-md-10 col-sm-6 col-6">
                <div className="navbarMenu">
                  <ul className="navItems">
                    <li className="menu-items">
                      <Link
                        to="/"
                        className={`${
                          currentUrl?.[3] === "" ? "active" : null
                        }`}
                      >
                        Home
                      </Link>
                    </li>
                    <li className="menu-items">
                      <Link
                        to="/about-us"
                        className={`${
                          currentUrl?.[3] === "about-us" ? "active" : null
                        }`}
                      >
                        About
                      </Link>
                    </li>

                    <li className="menu-items">
                      <Link
                        to="/shop"
                        className={`${
                          currentUrl?.[3] === "shop" ? "active" : null
                        }`}
                      >
                        Shop
                      </Link>
                    </li>
                    
                    {/* <li className="menu-items nav-item dropdown">
                      <a
                        className={`${
                          currentUrl?.[3] === "all-cars"
                            ? "nav-link dropdown-toggle active"
                            : "nav-link dropdown-toggle"
                        }`}
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Shop
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <Link
                          to={`/`}
                          className="dropdown-item header-drop header-drop-font"
                        >
                          Saga
                        </Link>
                        <Link
                          to={`/`}
                          className="dropdown-item header-drop header-drop-font"
                        >
                          CBD Oil
                        </Link>
                      </div>
                    </li> */}

                    <li className="menu-items">
                      <Link
                        to="/contact"
                        className={`${
                          currentUrl?.[3] === "contact" ? "active" : null
                        }`}
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                  <ul className="navItems">
                    <li className="menu-items">
                      <Link
                        to="/cart"
                        className={`${
                          currentUrl?.[3] === "cart" ? "active" : null
                        }`}
                      >
                        <FontAwesomeIcon
                          icon={faCartShopping}
                          className="modal-close"
                        />
                      </Link>
                    </li>
                    <li className="menu-items">
                      <Link
                        to="/"
                        className={`${
                          currentUrl?.[3] === "search" ? "active" : null
                        }`}
                      >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </Link>
                    </li>
                    <li className="menu-items">
                      <Link
                        to="/profile"
                        className={`${
                          currentUrl?.[3] === "profile" ? "active" : null
                        }`}
                      >
                        <FontAwesomeIcon
                          icon={faUser}
                          className="modal-close"
                        />
                      </Link>
                    </li>

                    <li className="menu-items btn">
                      {Token ? (
                        <Link
                          to="/login"
                          className={`${
                            currentUrl?.[3] === "list-your-ads"
                              ? "active"
                              : null
                          }`}
                        >
                         Login
                        </Link>
                      ) : (
                        <a
                          // onClick={(e)=>ListHandler(e)}
                          className={`${
                            currentUrl?.[3] === "login"
                              ? "active"
                              : null
                          }`}
                        >
                         Login
                        </a>
                      )}
                    </li>
                  </ul>
                  <div
                    className="canvas-icon text-white"
                    // onClick={()=>NavBarHandler()}
                  >
                    <i className="fa fa-bars" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- mobile header start --> */}
            <div
              className={`${mobView ? "mobile-header show" : "mobile-header"}`}
            >
              <div
                className="cancel"
                // onClick={()=>NavBarHandler()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </div>
              <ul className="mobile-nav">
                <li className="nav-item">
                  <Link
                    to="/"
                    className={`${currentUrl?.[3] === "" ? "active" : null}`}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/all-cars"
                    className={`${
                      currentUrl?.[3] === "all-cars" ? "active" : null
                    }`}
                  >
                    Shop
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/instant-offer"
                    className={`${
                      currentUrl?.[3] === "instant-offer" ? "active" : null
                    }`}
                  >
                    Instant Offer
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/research"
                    className={`${
                      currentUrl?.[3] === "research" ? "active" : null
                    }`}
                  >
                    Research
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/customize"
                    className={`${
                      currentUrl?.[3] === "customize" ? "active" : null
                    }`}
                  >
                    Customize
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/car-show"
                    className={`${
                      currentUrl?.[3] === "car-show" ? "active" : null
                    }`}
                  >
                    test
                  </Link>
                </li>
                {/* <li className="nav-item"><Link to="/merchandise"  className={`${currentUrl?.[3] === "merchandise" ? "active" : null}`}>Merchandise</Link></li> */}
                <li className="nav-item">
                  <Link
                    to="/about-us"
                    className={`${
                      currentUrl?.[3] === "about-us" ? "active" : null
                    }`}
                  >
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/list-your-ads"
                    className={`${
                      currentUrl?.[3] === "list-your-ads" ? "active" : null
                    }`}
                  >
                    List Your Vehicle
                  </Link>
                </li>
              </ul>
              {/* <!-- secondary header items --> */}
              <ul className="secondary-items">
                <li>
                  <a className="text-white downloads" href="#">
                    Download Game
                  </a>
                </li>
                {/* <li className="flexBox-style navP-mob">
                    {
                        isLogin ? (
                            <>
                            <Link to="/profile" >
                                <div className='header-profile'>
                                     {userData?.image == "def.png" ? (
                                    <img
                                    src={`${ramdomImage( `${userData?.f_name}{""}${userData?.l_name}`)}`}
                                    className="image-fluid image-width"
                                        style={{ borderRadius: "50%" }}
                                    alt=""
                                    />
                                    ) : (
                                        <img
                                        src={`${ImageUrl?.customer_image_url}/${userData?.image}`}
                                        className="image-fluid image-width"
                                        style={{height: "100%" }}
                                        alt=""
                                        />
                                    )}
                                </div>
                            </Link> 
                            <a 
                                className='ml-2 log' 
                                onClick={(e)=>LogoutHandler(e)}
                                style={{cursor: 'pointer'}}
                            >{loading ? "Loading.." : "Logout" }</a>
                            </>
                        ) : (
                            <Link to="/login" className='log'>Login</Link>
                        )
                    }
                </li> */}
                <li className="mr-5">
                  <Link to="/term-condition">Terms</Link>
                </li>
                {/* <li>
                    {
                        Token ? (
                            <Link to="/favourite" ><i className="fa fa-heart" aria-hidden="true"></i></Link>
                        ) : (
                            <a onClick={(e)=>ListHandler(e)} ><i className="fa fa-heart" aria-hidden="true"></i></a>
                        )
                    }
                    </li> */}
                <li className="cartItem">
                  <Link to="/cart">
                    <i className="fa fa-shopping-cart" aria-hidden="true">
                      <span className="chat-count">0</span>
                    </i>
                  </Link>
                </li>
                {/* <li className="comments">
                    {
                        Token ? (
                            <Link to="/chat"><i className="fa fa-commenting-o" aria-hidden="true"></i>
                                <span className='chat-count'>{CounterRedux}</span>
                            </Link>
                        ) : (
                            <a onClick={(e)=>ListHandler(e)} ><i className="fa fa-commenting-o" aria-hidden="true"></i></a>
                        )
                     }
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
